<template>
  <div class="h-min-content xl:w-full sm:w-full px-0">
    <div v-if="loading" class="spinner mx-auto">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>

    <Accordion header="Reporte de reincidencias ">
      <Products_Reasons
        v-if="!loading"
        :config="{
          annual_report,
          report_type,
          refProductsFiles: 7,
          refReasonsFiles: 8,
        }"
        :data="reasons_products_data"
        @products="productsSelected = $event"
        @reasons="reasonsSelected = $event"
        @on-error="onErrorProductReasons"
        :saving="toSave"
        :loading="loading"
        :lectureMode="lectureMode" />

      <h2 class="my-6 text-left text-xl text-gray-900 font-bold w-full">
        Reporte de reincidencias
      </h2>
      <div id="reincidents-report flex gap-4">
        <div class="w-1/3">
          <BaseInput
            required="true"
            numeric
            maxlength="5"
            :disabled="lectureMode"
            label="Número de reincidencias"
            v-model="sectionsForm.number_recurrences"
          />
        </div>
        <BaseTextarea
         required="true"
          :disabled="lectureMode"
          label="Descripción de la novedad"
          maxlength="4500"
          rows="3"
          v-model="sectionsForm.description"
        ></BaseTextarea>
        <BaseTextarea
         required="true"
          :disabled="lectureMode"
          label="Considero que el DCF carecía de competencia"
          maxlength="4500"
          rows="3"
          v-model="sectionsForm.lacked_competence"
        ></BaseTextarea>
        <BaseTextarea
         required="true"
          :disabled="lectureMode"
          label="No hubo colaboración con el DCF, no se suministró la información requerida"
          maxlength="4500"
          rows="3"
          v-model="sectionsForm.no_collaboration_dcf"
        ></BaseTextarea>
        <BaseTextarea
         required="true"
          :disabled="lectureMode"
          label="No se aceptó el pronunciamiento del DCF a favor del consumidor financiero"
          maxlength="4500"
          rows="3"
          v-model="sectionsForm.not_dcf_pronouncement"
        ></BaseTextarea>
      </div>
      <h2 class="my-6 text-left text-xl text-gray-900 font-bold">
        Pronunciamientos
      </h2>
      <p>
        Relación y breve descripción de los pronunciamientos que el DCF, a su
        juicio, considere de interés general.
      </p>
      <div
        id="pronouncements"
        class="px-5 pb-8 pt-5 flex flex-col gap-4 w-full"
      >
        <div class="w-2/3 mx-0">
          <BaseInput
            numeric
             required="true"
            maxlength="5"
            class=""
            :disabled="lectureMode"
            label="Número total de pronunciamientos del DCF"
            v-model="sectionsForm.number_pronouncement"
          />
        </div>
        <BaseTextarea
         required="true"
          :disabled="lectureMode"
          label="Descripción"
          maxlength="4500"
          rows="3"
          v-model="sectionsForm.description_pronouncement"
        ></BaseTextarea>
        <div v-if="annexes.sectionAnnexesLoad.length > 0">
          <h2 class="text-left text-gray-500 text-sm font-bold">
            Archivos existentes
          </h2>
          <div class="w-11/12 grid grid-cols-1 gap-2">
            <BaseDownload
              v-for="(option, index) in annexes.sectionAnnexesLoad"
              :value="option.name_file"
              :key="index"
              :url_download="option.url_file"
            />
          </div>
        </div>
        <DragDrop
          v-if="!lectureMode"
          label="Anexar archivos de soporte:"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          id="complaintFiles"
          :fileList="sectionsFiles"
          @files-handler="setSectionsFiles"
          maxMB="20"
        /></div
    ></Accordion>
  </div>
</template>

<script>
import Products_Reasons from "@/components/Dashboard/FCO/Reports/Products_Reasons.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  components: { Products_Reasons, Accordion },
  props: {
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  data() {
    return {
      company_id: this.$route.query.company_id,
      annual_report: this.$route.query.annual_report,
      report_type: 2, // Step 2
      reasons_products_data: null,
      sectionsForm: {
        reference_file: 9,
        number_recurrences: "",
        description: "",
        lacked_competence: "",
        no_collaboration_dcf: "",
        not_dcf_pronouncement: "",
        number_pronouncement: "",
        description_pronouncement: "",
      },

      annexes: {
        sectionAnnexesLoad: [],
      },

      sectionsFiles: [],
      jsonToCompare: "",
      loading: false,
      toSave: false,
    };
  },

  created() {
    this.getProducts();
    this.getReasons();
    this.getReport();
  },
  methods: {
    async getReport() {
      this.loading = true;
      let { error, data } = await this.$api.getStepReport(
        this.$route.query.annual_report,
        2
      );
      if (data) {
        this.reasons_products_data = data.data;
        this.loading = false;
        this.loadSections(data.data);
        this.jsonToCompare = this.generateJsonToCompare();
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    onErrorProductReasons(msg) {
      this.showToast("info", msg + ' en el Paso 2.');
      this.$emit('on-step-error', msg + ' en el Paso 2.', 1);
    },
    loadSections(data) {
      this.sectionsForm.number_pronouncement =
        data.incident.number_pronouncement;

      this.sectionsForm.description = data.incident.description;

      this.sectionsForm.description_pronouncement =
        data.incident.description_pronouncement;

      this.sectionsForm.lacked_competence = data.incident.lacked_competence;

      this.sectionsForm.no_collaboration_dcf =
        data.incident.no_collaboration_dcf;

      this.sectionsForm.not_dcf_pronouncement =
        data.incident.not_dcf_pronouncement;

      this.sectionsForm.number_recurrences = data.incident.number_recurrences;
      this.annexes.sectionAnnexesLoad = data.annexes.filter(
        (annexe) => (annexe.reference = 9)
      );
    },
    async save() {
      const sectionsResult = await this.saveSection();
      if (sectionsResult === false) {
        this.showToast("info", "Algunos datos no se guardaron correctamente.");
      } else {
        this.showToast("success", "Guardando como borrador...");
      }
    },
    generateJsonToCompare() {
      return JSON.stringify(this.sectionsForm);
    },
    async saveSection() {
      this.sectionsForm.annual_report = this.annual_report;
      const emptyFields = []
      const fields = {
        number_recurrences:"Número de reincidencias",
        lacked_competence:"Considero que el DCF carecía de competencia",
        no_collaboration_dcf:"No hubo colaboración con el DCF, no se suministró la información requerida",
        not_dcf_pronouncement:"No se aceptó el pronunciamiento del DCF a favor del consumidor financiero",
        number_pronouncement:"Número total de pronunciamientos del DCF",
        description: "Descripción de la novedad",
        description_pronouncement: "Descripción del pronunciamiento"
      }
      Object.keys(this.sectionsForm).forEach(key => {
        if (["", null, undefined].includes(this.sectionsForm[key])) {
          emptyFields.push(fields[key])
        }
      })
      if (emptyFields.length > 0) {
        const msg = "En el Paso 2 algunos campos quedaron incompletos. " + emptyFields.join(", ")
        this.showToast("info", msg)
        this.$emit('on-step-error', msg, 1);
      }
      const forms = { form: this.sectionsForm, files: this.sectionsFiles };
      let { error, data } = await this.$api.createIncidentReport(forms);
      if (data) return true;
      if (error) return false;
    },

    setSectionsFiles(event) {
      this.sectionsFiles = event;
    },
    async getProducts() {
      this.optionsProducts = await this.$store.dispatch(
        "form/loadProductsOptions",
        {
          company: this.company_id,
        }
      );
    },
    async getReasons() {
      this.optionsReasons = await this.$store.dispatch(
        "form/loadReasonsOptions",
        {
          company: this.company_id,
        }
      );
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  beforeDestroy() {
    //TO_DO: Validate if there are changes in all forms

    if (!this.lectureMode) {
      if (this.jsonToCompare !== this.generateJsonToCompare()) this.save();
    }
  },
  computed: {
    buildJsonForm: function () {},
    getProductsSelected: function () {
      return;
    },
  },
  watch: {
    saveSignal: function (value) {
      if (value) {
        if (this.jsonToCompare !== this.generateJsonToCompare()) {
          this.save();
        }
        this.toSave = true;
        setTimeout(() => {
          this.toSave = false;
        }, 1500);
      }
    },
  },
};
</script>

<style scoped>
.spinner {
  width: 100%;
  z-index: 9000;
  margin-top: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>
