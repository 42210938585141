<template>
  <div>
    <div
      v-for="(product, idx) in section.new_products"
      :key="idx"
      id="products"
      class="px-5 pb-8 pt-5 flex flex-col gap-4 w-full rounded-xl border mb-5 relative"
    >
      <h3 class="text-left font-bold">Producto</h3>
      <div
        class="w-full mx-0"
        v-if="
          values.selectOptions.products.length > 0 &&
          values.selectOptions.reasons.length > 0
        "
      >
        <div class="grid grid-cols-2 justify-between w-full gap-5">
          <a-button
            v-if="!lectureMode"
            icon="delete"
            class="border-none text-xl absolute top-0 right-0 mt-3 mr-3"
            @click="deleteProduct(idx)"
          ></a-button>
          <BaseSelect
            required="true"
            :disabled="lectureMode"
            class=""
            label="Seleccione el producto"
            :selectOptions="values.selectOptions.products"
            v-model="section.new_products[idx].product"
          />
          <BaseMultiSelect
            v-if="values.selectOptions.reasons.length > 0"
            title="Seleccione los motivos"
            placeholder="Seleccionar"
            :data="values.selectOptions.reasons"
            :valuesSelected="multiSelectLoad[idx]"
            label="name"
            @notify-objects="setReasonsSelect($event, idx)"
            class="relative"
            required
          />
        </div>
        <BaseInput
          :disabled="lectureMode"
          ref="total-complaints"
          :label="config.numInputLabel"
          placeholder="12389"
          maxlength="5"
          required="true"
          numeric
          v-model="section.new_products[idx].amount"
        />
      </div>
      <div
        v-else-if="
          !lectureMode &&
          values.selectOptions.products.length == 0 &&
          values.selectOptions.reasons.length == 0
        "
        class="bg-red-50 rounded-xl p-6 text-red-500"
      >
        * Es necesario que preseleccione mínimo un producto y motivo
        <a-button
            v-if="!lectureMode"
            icon="delete"
            class="border-none text-xl absolute top-0 right-0 mt-3 mr-3"
            @click="deleteProduct(idx)"
        ></a-button>
      </div>
    </div>

    <div
      v-if="!lectureMode"
      id="add-product"
      class="px-5 py-3 flex gap-4 w-full rounded-xl border items-center cursor-pointer"
    >
      <a-button
        :id="section.reference_file"
        size="large"
        type="primary"
        shape="circle"
        icon="plus"
        @click="addProduct"
      >
      </a-button>
      <label :for="section.reference_file" class="font-bold">Añadir producto</label>
    </div>
    <BaseTextarea
      required="true"
      :disabled="lectureMode"
      label="Descripción"
      maxlength="4500"
      rows="4"
      v-model="section.description"
    ></BaseTextarea>
    <div v-if="values.annexesLoad.length > 0">
      <h2 class="text-left text-gray-500 text-sm font-bold mt-4">
        Archivos existentes
      </h2>
      <div class="w-11/12 grid grid-cols-1 gap-2">
        <BaseDownload
          v-for="(option, index) in values.annexesLoad"
          :value="option.name_file"
          :key="index"
          :url_download="option.url_file"
        />
      </div>
    </div>
    <DragDrop
      v-if="!lectureMode"
      :id="`files${config.section}`"
      label="Anexar archivos de soporte:"
      buttonLabel="SELECCIONE SUS ARCHIVOS"
      :fileList="section.files"
      @files-handler="setSectionFiles"
      maxMB="20"
    />
  </div>
</template>
<script>
export default {
  props: {
    lectureMode: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    values: {
      type: Object,
      default: {
        selectOptions: [],
        selectedOptions: [],
        section: {
          descriptionValue: "",
          section_report_type: "",
          reference_file: "",
        },
        annexesLoad: [],

        productValue: [],
      },
    },

    config: {
      type: Object,
      default: { numInputLabel: "Input label", section: "Section" },
    },
  },
  created() {
    this.multiSelectLoad = this.values.selectedOptions;
    this.section.section_report_type = this.values.section.section_report_type;
    this.section.reference_file = this.values.section.reference_file;
    this.section.description = this.values.descriptionValue;
  },
  data() {
    return {
      section: {
        files: [],
        description: "",
        section_report_type: "",
        reference_file: "",
        new_products: [],
        del_products: [],
      },
      multiSelectLoad: [],
    };
  },
  methods: {
    addProduct() {
      this.section.new_products.push({
        product: "",
        amount: "",
        reasons: [],
      });
    },
    deleteProduct(idx) {
      const product = this.section.new_products.splice(idx, 1);

      if (
        product[0].amount != "" &&
        product[0]?.reasons?.length > 0 &&
        product[0].product != ""
      )
        this.section.del_products.push(product[0]);
    },
    setSectionFiles(files) {
      this.section.files = files;
    },
    setReasonsSelect(reasons, idx) {
      this.section.new_products[idx].reasons = reasons.map((item) => item.id);
    },
  },
  computed: {},
  watch: {
    section: {
      handler(oldValue, newValue) {
        this.section.section = this.config.section;
        this.$emit("product", this.section);
      },
      deep: true,
    },
  },
};
</script>
