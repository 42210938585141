var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-min-content xl:w-full sm:w-full px-0"},[(_vm.loading)?_c('div',{staticClass:"spinner mx-auto"},[_c('a-spin',[_c('a-icon',{staticStyle:{"font-size":"64px"},attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"})],1)],1):_vm._e(),_c('Accordion',{attrs:{"header":"De la función de conciliación"}},[(!_vm.loading)?_c('Products_Reasons',{attrs:{"config":{
        annual_report: _vm.annual_report,
        report_type: _vm.report_type,
        refProductsFiles: 13,
        refReasonsFiles: 14,
      },"data":_vm.reasons_products_data,"saving":_vm.toSave,"loading":_vm.loading,"lectureMode":_vm.lectureMode},on:{"products":function($event){_vm.productsSelected = $event},"reasons":function($event){_vm.reasonsSelected = $event},"on-error":_vm.onErrorProductReasons}}):_vm._e(),_c('h2',{staticClass:"my-6 text-left text-xl text-gray-900 font-bold"},[_vm._v(" Relación del número de conciliaciones solicitadas por los consumidores finacieros y el número de conciliaciones realizadas, discriminando aquellas en las que se llegó a mutuo acuerdo. ")]),_c('div',{staticClass:"flex flex-col gap-6"},[_c('Accordion',{attrs:{"header":"Solicitudes de conciliación recibidas "}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.receivedLoad,
            section: _vm.receivedSection,
            productsValue: [],
            annexesLoad: _vm.annexes.receivedAnnexesLoad,
          },"config":{
            numInputLabel: 'Número de solicitudes de conciliación recibidas',
            section: 'received',
          }},on:{"product":_vm.getSectionValues}})],1),_c('Accordion',{attrs:{"header":"Solicitudes admitidas"}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.admitedLoad,
            section: _vm.admitedSection,
            productsValue: [],
            annexesLoad: _vm.annexes.admitedAnnexesLoad,
          },"config":{
            numInputLabel: 'Número de solicitudes admitidas',
            section: 'admited',
          }},on:{"product":_vm.getSectionValues}})],1),_c('Accordion',{attrs:{"header":"Solicitudes rechazadas "}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.rejectedLoad,
            section: _vm.rejectedSection,
            productsValue: [],
            annexesLoad: _vm.annexes.rejectedAnnexesLoad,
          },"config":{
            numInputLabel: 'Número de solicitudes rechazadas',
            section: 'rejected',
          }},on:{"product":_vm.getSectionValues}})],1),_c('Accordion',{attrs:{"header":"No acuerdos"}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.noAgreementsLoad,
            section: _vm.noAgreementsSection,
            productsValue: [],
            annexesLoad: _vm.annexes.noAgreementsAnnexesLoad,
          },"config":{
            numInputLabel: 'Número de no acuerdos',
            section: 'noAgreements',
          }},on:{"product":_vm.getSectionValues}})],1),_c('Accordion',{attrs:{"header":"Solicitudes de audiencias desistidas"}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.abandonedLoad,
            section: _vm.abandonedSection,
            productsValue: [],
            annexesLoad: _vm.annexes.abandonedAnnexesLoad,
          },"config":{
            numInputLabel: 'Número de audiencias desistidas',
            section: 'abandoned',
          }},on:{"product":_vm.getSectionValues}})],1),_c('Accordion',{attrs:{"header":"Audiencias no atendidas"}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.noServedLoad,
            section: _vm.noServedSection,
            productsValue: [],
            annexesLoad: _vm.annexes.noServedAnnexesLoad,
          },"config":{
            numInputLabel: 'Número de audiencias no atendidas',
            section: 'noServed',
          }},on:{"product":_vm.getSectionValues}})],1)],1),(_vm.annexes.generalAnnexesLoad.length > 0)?_c('div',[_c('h2',{staticClass:"text-left text-gray-500 text-sm font-bold"},[_vm._v(" Archivos existentes ")]),_c('div',{staticClass:"w-11/12 grid grid-cols-1 gap-2"},_vm._l((_vm.annexes.generalAnnexesLoad),function(option,index){return _c('BaseDownload',{key:index,attrs:{"value":option.name_file,"url_download":option.url_file}})}),1)]):_vm._e(),(!_vm.lectureMode)?_c('DragDrop',{attrs:{"label":"Anexar archivos de soporte:","buttonLabel":"SELECCIONE SUS ARCHIVOS","id":"complaintFiles","fileList":_vm.generalSectionFiles},on:{"files-handler":_vm.setFiles}}):_vm._e(),_c('h2',{staticClass:"my-6 text-left text-xl text-gray-900 font-bold"},[_vm._v(" Un resumen de los casos conciliados entre consumidores financieros y la entidad vigilada que, a juicio del DCF, sean relevantes: ")]),_c('Accordion',{attrs:{"header":"Casos relevantes"}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
          selectOptions: {
            products: _vm.productsSelected,
            reasons: _vm.reasonsSelected,
          },
          selectedOptions: _vm.multiSelectLoad.relevantLoad,
          section: _vm.relevantSection,
          productsValue: [],
          annexesLoad: _vm.annexes.relevantAnnexesLoad,
        },"config":{
          numInputLabel: 'Número de casos relevantes',
          section: 'relevant',
        }},on:{"product":_vm.getSectionValues}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }