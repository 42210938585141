<template>
  <div class="h-min-content xl:w-full sm:w-full px-0">
    <div v-if="loading" class="spinner mx-auto">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <Accordion header="De la función de conciliación">
      <Products_Reasons
        v-if="!loading"
        :config="{
          annual_report,
          report_type,
          refProductsFiles: 13,
          refReasonsFiles: 14,
        }"
        :data="reasons_products_data"
        @products="productsSelected = $event"
        @reasons="reasonsSelected = $event"
        @on-error="onErrorProductReasons"
        :saving="toSave"
        :loading="loading"
        :lectureMode="lectureMode"
      />

      <h2 class="my-6 text-left text-xl text-gray-900 font-bold">
        Relación del número de conciliaciones solicitadas por los consumidores
        finacieros y el número de conciliaciones realizadas, discriminando
        aquellas en las que se llegó a mutuo acuerdo.
      </h2>
      <div class="flex flex-col gap-6">
        <Accordion header="Solicitudes de conciliación recibidas ">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.receivedLoad,
              section: receivedSection,
              productsValue: [],
              annexesLoad: annexes.receivedAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Número de solicitudes de conciliación recibidas',
              section: 'received',
            }"
          />
        </Accordion>
        <Accordion header="Solicitudes admitidas">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.admitedLoad,
              section: admitedSection,
              productsValue: [],
              annexesLoad: annexes.admitedAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Número de solicitudes admitidas',
              section: 'admited',
            }"
          />
        </Accordion>
        <Accordion header="Solicitudes rechazadas ">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.rejectedLoad,
              section: rejectedSection,
              productsValue: [],
              annexesLoad: annexes.rejectedAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Número de solicitudes rechazadas',
              section: 'rejected',
            }"
          />
        </Accordion>
        <Accordion header="No acuerdos">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.noAgreementsLoad,
              section: noAgreementsSection,
              productsValue: [],
              annexesLoad: annexes.noAgreementsAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Número de no acuerdos',
              section: 'noAgreements',
            }"
          />
        </Accordion>
        <Accordion header="Solicitudes de audiencias desistidas">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.abandonedLoad,
              section: abandonedSection,
              productsValue: [],
              annexesLoad: annexes.abandonedAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Número de audiencias desistidas',
              section: 'abandoned',
            }"
          />
        </Accordion>
        <Accordion header="Audiencias no atendidas">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.noServedLoad,
              section: noServedSection,
              productsValue: [],
              annexesLoad: annexes.noServedAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Número de audiencias no atendidas',
              section: 'noServed',
            }"
          />
        </Accordion>
      </div>
      <div v-if="annexes.generalAnnexesLoad.length > 0">
        <h2 class="text-left text-gray-500 text-sm font-bold">
          Archivos existentes
        </h2>
        <div class="w-11/12 grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in annexes.generalAnnexesLoad"
            :value="option.name_file"
            :key="index"
            :url_download="option.url_file"
          />
        </div>
      </div>
      <DragDrop
        v-if="!lectureMode"
        label="Anexar archivos de soporte:"
        buttonLabel="SELECCIONE SUS ARCHIVOS"
        id="complaintFiles"
        :fileList="generalSectionFiles"
        @files-handler="setFiles"
      />
      <h2 class="my-6 text-left text-xl text-gray-900 font-bold">
        Un resumen de los casos conciliados entre consumidores financieros y la
        entidad vigilada que, a juicio del DCF, sean relevantes:
      </h2>
      <Accordion header="Casos relevantes">
        <Products
          :lectureMode="lectureMode"
          @product="getSectionValues"
          :values="{
            selectOptions: {
              products: productsSelected,
              reasons: reasonsSelected,
            },
            selectedOptions: multiSelectLoad.relevantLoad,
            section: relevantSection,
            productsValue: [],
            annexesLoad: annexes.relevantAnnexesLoad,
          }"
          :config="{
            numInputLabel: 'Número de casos relevantes',
            section: 'relevant',
          }"
        />
      </Accordion>
    </Accordion>
  </div>
</template>

<script>
import Products_Reasons from "@/components/Dashboard/FCO/Reports/Products_Reasons.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import Products from "@/components/Dashboard/FCO/Reports/Products.vue";
export default {
  components: { Products_Reasons, Accordion, Products },
  props: {
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  data() {
    return {
      company_id: this.$route.query.company_id,
      annual_report: this.$route.query.annual_report,
      reasons_products_data: null,
      report_type: 4, // Step 4

      receivedSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 5,
        reference_file: 15,
      },
      admitedSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 6,
        reference_file: 16,
      },
      rejectedSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 7,
        reference_file: 17,
      },
      noAgreementsSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 8,
        reference_file: 18,
      },
      abandonedSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 9,
        reference_file: 19,
      },
      noServedSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 10,
        reference_file: 20,
      },
      relevantSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 11,
        reference_file: 21,
      },

      multiSelectLoad: {
        receivedLoad: [],
        admitedLoad: [],
        rejectedLoad: [],
        noAgreementsLoad: [],
        abandonedLoad: [],
        noServedLoad: [],
        relevantLoad: [],
      },
      annexes: {
        admitedAnnexesLoad: [],
        receivedAnnexesLoad: [],
        rejectedAnnexesLoad: [],
        noAgreementsAnnexesLoad: [],
        abandonedAnnexesLoad: [],
        noServedAnnexesLoad: [],
        relevantAnnexesLoad: [],
        generalAnnexesLoad: [],
      },
      generalSectionFiles: [],
      productsSelected: [],
      reasonsSelected: [],
      jsonToCompare: "",
      loading: false,
      toSave: false,
    };
  },
  created() {
    this.getProducts();
    this.getReasons();
    this.getReport();
  },
  methods: {
    /*
    async getReport(loading = true) {
      this.loading = true;
      let { error, data } = await this.$api.getStepReport(
        this.$route.query.annual_report,
        4
      );
      if (data) {
        this.reasons_products_data = data.data;
        this.loading = false;
        this.loadSections(data.data);
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    */
    async getReport() {
      this.loading = true;
      let { error, data } = await this.$api.getStepReport(
        this.$route.query.annual_report,
        4
      );
      if (data) {
        this.reasons_products_data = data.data;
        this.loading = false;
        this.loadSections(data.data);
        this.jsonToCompare = this.generateJsonToCompare();
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    loadSections(data) {
      data.product_reason.forEach((section) => {
        switch (section.section_report_type) {
          case 5:
            this.receivedSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.receivedSection.new_products = section.products;
            this.receivedSection.new_products.forEach((product) => {
              this.multiSelectLoad.receivedLoad.push(product.reasons);
            });
            this.annexes.receivedAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 15
            );
            break;
          case 6:
            this.admitedSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.admitedSection.new_products = section.products;
            this.admitedSection.new_products.forEach((product) => {
              this.multiSelectLoad.admitedLoad.push(product.reasons);
            });
            this.annexes.admitedAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 16
            );
            break;
          case 7:
            this.rejectedSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.rejectedSection.new_products = section.products;
            this.rejectedSection.new_products.forEach((product) => {
              this.multiSelectLoad.rejectedLoad.push(product.reasons);
            });

            this.annexes.rejectedAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 17
            );
            break;
          case 8:
            this.noAgreementsSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.noAgreementsSection.new_products = section.products;
            this.noAgreementsSection.new_products.forEach((product) => {
              this.multiSelectLoad.noAgreementsLoad.push(product.reasons);
            });
            this.annexes.noAgreementsAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 18
            );
            break;
          case 9:
            this.abandonedSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.abandonedSection.new_products = section.products;
            this.abandonedSection.new_products.forEach((product) => {
              this.multiSelectLoad.abandonedLoad.push(product.reasons);
            });
            this.annexes.abandonedAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 19
            );
            break;
          case 10:
            this.noServedSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.noServedSection.new_products = section.products;
            this.noServedSection.new_products.forEach((product) => {
              this.multiSelectLoad.noServedLoad.push(product.reasons);
            });
            this.annexes.noServedAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 20
            );
            break;
          case 11:
            this.relevantSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.relevantSection.new_products = section.products;
            this.relevantSection.new_products.forEach((product) => {
              this.multiSelectLoad.relevantLoad.push(product.reasons);
            });
            this.annexes.relevantAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 21
            );
            break;
        }
      });
    },
    onErrorProductReasons(msg) {
      this.showToast("info", msg + ' en el Paso 4.');
      this.$emit('on-step-error', msg + ' en el Paso 4.', 3);
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },

    async save() {
      const receivedResult = await this.saveSection(
          this.receivedSection,
          'Número de solicitudes de conciliación recibidas'
      );
      const noAgreementsResult = await this.saveSection(
        this.noAgreementsSection,
          "No acuerdos"
      );
      const admitedResult = await this.saveSection(
          this.admitedSection,
          "Solicitudes admitidas"
      );
      const rejectedResult = await this.saveSection(
          this.rejectedSection,
          "Solicitudes rechazadas"
      );
      const abandonedResult = await this.saveSection(
          this.abandonedSection,
          "Solicitudes de audiencias desistidas"
      );
      const noServedResult = await this.saveSection(
          this.noServedSection,
          "Audiencias no atendidas"
      );
      const relevantResult = await this.saveSection(
          this.relevantSection,
          "Casos relevantes"
      );

      if (
        receivedResult === false ||
        noAgreementsResult === false ||
        admitedResult === false ||
        rejectedResult === false ||
        relevantResult === false ||
        noServedResult === false ||
        abandonedResult === false
      ) {
        this.showToast("info", "Algunos datos no se guardaron correctamente.");
      } else {
        this.showToast("success", "Guardando como borrador...");
      }
    },
    async saveSection(formSection, name="") {
      const forms = this.buildFormDataSection(formSection, name);
      let { error, data } = await this.$api.createSectionReport(forms);
      if (data) return true;
      if (error) return false;
    },

    buildFormDataSection(formSection, name = '') {
      const files = formSection.files;

      formSection.del_products = JSON.stringify(formSection.del_products);
      formSection.annual_report = this.annual_report;
      formSection.new_products = this.clearProducts(formSection.new_products, name);
      formSection.new_products = JSON.stringify(formSection.new_products);

      delete formSection.section;
      delete formSection.files;
      return { form: formSection, files };
    },
    clearProducts(products, name="") {
      let newProducts = [];
      products.forEach((product) => {
        if (
          product.amount != "" &&
          product?.reasons?.length > 0 &&
          product.product != ""
        ) {
          newProducts.push(product);
        }
      });
      if ( products.length !== newProducts.length ) {
        const msg = `En el Paso 4 en la sección ${name} Algunos productos no fueron guardados porque faltaban datos en el formulario.`
        this.showToast("info", msg);
        this.$emit('on-step-error', msg, 3);
      }
      return newProducts;
    },

    setFiles(event) {
      this.generalSectionFiles = event;
    },
    generateJsonToCompare() {
      let array = [];
      array.push(this.receivedSection);
      array.push(this.admitedSection);
      array.push(this.rejectedSection);
      array.push(this.noAgreementsSection);
      array.push(this.noServedSection);
      array.push(this.relevantSection);
      array.push(this.abandonedSection);
      array.push(this.generalSectionFiles);
      return JSON.stringify(array);
    },
    getSectionValues(sectionProduct) {
      switch (sectionProduct.section) {
        case "received":
          this.receivedSection = sectionProduct;
          break;
        case "admited":
          this.admitedSection = sectionProduct;
          break;
        case "rejected":
          this.rejectedSection = sectionProduct;
          break;
        case "noAgreements":
          this.noAgreementsSection = sectionProduct;
          break;
        case "noServed":
          this.noServedSection = sectionProduct;
          break;
        case "abandoned":
          this.abandonedSection = sectionProduct;
          break;
        case "relevant":
          this.relevantSection = sectionProduct;
          break;
      }
    },

    async getProducts() {
      this.optionsProducts = await this.$store.dispatch(
        "form/loadProductsOptions",
        {
          company: this.company_id,
        }
      );
    },
    async getReasons() {
      this.optionsReasons = await this.$store.dispatch(
        "form/loadReasonsOptions",
        {
          company: this.company_id,
        }
      );
    },
  },
  beforeDestroy() {
    //TO_DO: Validate if there are changes in all forms

    if (!this.lectureMode) {
      if (this.jsonToCompare !== this.generateJsonToCompare()) this.save();
    }
  },
  computed: {
    buildJsonForm: function () {},
    getProductsSelected: function () {
      return;
    },
  },
  watch: {
    saveSignal: function (value) {
      if (value) {
        if (this.jsonToCompare !== this.generateJsonToCompare()) {
          this.save();
        }
        this.toSave = true;
        setTimeout(() => {
          this.toSave = false;
        }, 1500);
      }
    },
  },
};
</script>

<style scoped>
.spinner {
  width: 100%;
  z-index: 9000;
  margin-top: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>
