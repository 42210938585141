<template>
  <div class="h-min-content xl:w-full sm:w-full px-0">
    <div v-if="loading" class="spinner mx-auto">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <Accordion header="De la función de atención y resolución de quejas">
      <Products_Reasons
        v-if="!loading"
        :config="{
          annual_report,
          report_type,
          refProductsFiles: 1,
          refReasonsFiles: 2,
        }"
        :data="reasons_products_data"
        @products="productsSelected = $event"
        @reasons="reasonsSelected = $event"
        @on-error="onErrorProductReasons"
        :saving="toSave"
        :loading="loading"
        :lectureMode="lectureMode"
      />
      <h2 class="my-6 text-left text-xl text-gray-900 font-bold">
        Reporte de quejas
      </h2>
      <div class="flex flex-col gap-6">
        <Accordion header="Quejas terminadas por rectificación ">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.rectificationLoad,
              section: rectificationSection,
              productsValue: [],
              annexesLoad: annexes.rectificationAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Quejas terminadas por rectificación',
              section: 'rectification',
            }"
          />
        </Accordion>
        <Accordion header="Quejas terminadas por desistimiento">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.withdrawalLoad,
              section: withdrawalSection,
              productsValue: [],
              annexesLoad: annexes.withdrawalAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Quejas terminadas por desistimiento',
              section: 'withdrawal',
            }"
          />
        </Accordion>
        <Accordion header="Quejas terminadas por inadmisión">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.inadmissionLoad,
              section: inadmissionSection,
              productsValue: [],
              annexesLoad: annexes.inadmissionAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Quejas terminadas por inadmisión',
              section: 'inadmission',
            }"
          />
        </Accordion>
        <Accordion header="Quejas terminadas por decisión emitida por DCF">
          <Products
            :lectureMode="lectureMode"
            @product="getSectionValues"
            :values="{
              selectOptions: {
                products: productsSelected,
                reasons: reasonsSelected,
              },
              selectedOptions: multiSelectLoad.emitionLoad,
              section: emitionSection,
              productsValue: [],
              annexesLoad: annexes.emitionAnnexesLoad,
            }"
            :config="{
              numInputLabel: 'Quejas terminadas por decisión emitida por DCF',
              section: 'emition',
            }"
          />
        </Accordion></div
    ></Accordion>
  </div>
</template>

<script>
import Products_Reasons from "@/components/Dashboard/FCO/Reports/Products_Reasons.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import Products from "@/components/Dashboard/FCO/Reports/Products.vue";
export default {
  components: { Products_Reasons, Accordion, Products },
  props: {
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  data() {
    return {
      company_id: this.$route.query.company_id,
      annual_report: this.$route.query.annual_report,
      report_type: 1, // Step 1
      reasons_products_data: null,

      rectificationSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 1,
        reference_file: 3,
      },

      optionsProducts: [],
      optionsReasons: [],

      withdrawalSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 2,
        reference_file: 4,
      },
      inadmissionSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 3,
        reference_file: 5,
      },
      emitionSection: {
        new_products: [],
        del_products: [],
        files: [],
        description: "",
        section_report_type: 4,
        reference_file: 6,
      },

      multiSelectLoad: {
        inadmissionLoad: [],
        withdrawalLoad: [],
        rectificationLoad: [],
        emitionLoad: [],
      },
      annexes: {
        emitionAnnexesLoad: [],
        inadmissionAnnexesLoad: [],
        withdrawalAnnexesLoad: [],
        rectificationAnnexesLoad: [],
      },

      jsonToCompare: "",
      productsSelected: [],
      reasonsSelected: [],
      loading: false,
      toSave: false,
    };
  },

  created() {
    this.getReport();
  },
  methods: {
    async getReport(loading = true) {
      this.loading = loading;
      let { error, data } = await this.$api.getStepReport(
        this.$route.query.annual_report,
        1
      );
      if (data) {
        this.reasons_products_data = data.data;
        this.loading = false;
        this.loadSections(data.data);
        this.jsonToCompare = this.generateJsonToCompare();
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    loadSections(data) {
      data.product_reason.forEach((section) => {
        switch (section.section_report_type) {
          case 1:
            this.rectificationSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.rectificationSection.new_products = section.products;
            this.rectificationSection.new_products.forEach((product) => {
              this.multiSelectLoad.rectificationLoad.push(product.reasons);
            });
            this.annexes.rectificationAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 3
            );
            break;
          case 2:
            this.withdrawalSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.withdrawalSection.new_products = section.products;
            this.withdrawalSection.new_products.forEach((product) => {
              this.multiSelectLoad.withdrawalLoad.push(product.reasons);
            });
            this.annexes.withdrawalAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 4
            );
            break;
          case 3:
            this.inadmissionSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.inadmissionSection.new_products = section.products;
            this.inadmissionSection.new_products.forEach((product) => {
              this.multiSelectLoad.inadmissionLoad.push(product.reasons);
            });
            this.annexes.inadmissionAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 5
            );
            break;
          case 4:
            this.emitionSection.description =
              section.description !== "null" &&
              section.description !== "undefined"
                ? section.description
                : "";
            this.emitionSection.new_products = section.products;
            this.emitionSection.new_products.forEach((product) => {
              this.multiSelectLoad.emitionLoad.push(product.reasons);
            });
            this.annexes.emitionAnnexesLoad = data.annexes.filter(
              (annexe) => annexe.reference == 6
            );
            break;
        }
      });
    },
    onErrorProductReasons(msg) {
      this.showToast("info", msg + ' en el Paso 1.')
      this.$emit('on-step-error', msg + ' en el Paso 1.', 0);
    },
    getSectionValues(sectionProduct) {
      switch (sectionProduct.section) {
        case "rectification":
          this.rectificationSection = sectionProduct;
          break;
        case "withdrawal":
          this.withdrawalSection = sectionProduct;
          break;
        case "inadmission":
          this.inadmissionSection = sectionProduct;
          break;
        case "emition":
          this.emitionSection = sectionProduct;
          break;
      }
    },

    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },

    async save() {
      const rectificationResult = await this.saveSection(
        this.rectificationSection,
          "Quejas terminadas por rectificación"
      );
      const emitionResult = this.saveSection(
          this.emitionSection,
          "Quejas terminadas por decisión emitida por DCF"
      );
      const withdrawalResult = this.saveSection(
          this.withdrawalSection,
      "Quejas terminadas por desistimiento"
      );
      const inadmissionResult = this.saveSection(
          this.inadmissionSection,
          "Quejas terminadas por inadmisión"
      );

      if (
        rectificationResult === false ||
        emitionResult === false ||
        withdrawalResult === false ||
        inadmissionResult === false
      ) {
        this.showToast("info", "Algunos datos no se guardaron correctamente.");
      } else {
        this.showToast("success", "Guardando como borrador...");
      }
    },
    async saveSection(formSection, name = '') {
      if (formSection.new_products.length < 1 || ["", null, undefined].includes(formSection.description)) {
        const msg = `En el Paso 1, por favor revisar la sección "${name}", faltan productos o no tiene descripción.`;
        this.showToast("info", msg);
        this.$emit('on-step-error', msg, 0);
        return false;
      }
      const forms = this.buildFormDataSection(formSection, name);
      let { error, data } = await this.$api.createSectionReport(forms);
      if (data) return true;
      if (error) return false;
    },

    buildFormDataSection(formSection, name = '') {
      const files = formSection.files;

      formSection.del_products = JSON.stringify(formSection.del_products);
      formSection.annual_report = this.annual_report;
      formSection.new_products = this.clearProducts(formSection.new_products, name);
      formSection.new_products = JSON.stringify(formSection.new_products);

      delete formSection.section;
      delete formSection.files;
      return { form: formSection, files };
    },
    clearProducts(products, name = '') {
      let newProducts = [];
      products.forEach((product) => {
        if (
          product.amount != "" &&
          product?.reasons?.length > 0 &&
          product.product != ""
        ) {
          newProducts.push(product);
        }
      });
      if ( products.length !== newProducts.length ) {
        const msg = "En el Paso 1 Algunos productos no fueron guardados porque faltaban datos en el formulario.";
        const sect = name ? ` Revisar sección "${name}".` : ''
        this.showToast("info", msg + sect);
        this.$emit('on-step-error', msg + sect, 0);
      }
      return newProducts;
    },

    generateJsonToCompare() {
      let array = [];
      array.push(this.rectificationSection);
      array.push(this.withdrawalSection);
      array.push(this.inadmissionSection);
      array.push(this.emitionSection);
      return JSON.stringify(array);
    },
    async getProducts() {
      this.optionsProducts = await this.$store.dispatch(
        "form/loadProductsOptions",
        {
          company: this.company_id,
        }
      );
    },
    async getReasons() {
      this.optionsReasons = await this.$store.dispatch(
        "form/loadReasonsOptions",
        {
          company: this.company_id,
        }
      );
    },
  },
  beforeDestroy() {
    //TO_DO: Validate if there are changes in all forms

    if (!this.lectureMode) {
      if (this.jsonToCompare !== this.generateJsonToCompare()) this.save();
    }
  },
  computed: {
    buildJsonForm: function () {},
    getProductsSelected: function () {
      return;
    },
  },
  watch: {
    saveSignal: function (value) {
      if (value) {
        if (this.jsonToCompare !== this.generateJsonToCompare()) {
          this.save();
        }
        this.toSave = true;
        setTimeout(() => {
          this.toSave = false;
        }, 1500);
      }
    },
  },
};
</script>

<style scoped>
.spinner {
  width: 100%;
  z-index: 9000;
  margin-top: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>
