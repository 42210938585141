<template>
  <div class="h-min-content xl:w-full sm:w-full px-0">
    <div v-if="loading" class="spinner mx-auto">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <Accordion header="Información adicional">
      <div class="flex flex-col gap-6">
        <p class="text-gray-500 text-sm text-justify">
          Conclusiones de las reuniones que durante el periodo realizaron con la
          revisoría fiscal, las áreas del control interno, servicio al
          consumidor financiero y otras oficinas, dependencias o funcionarios de
          la entidad para evaluar los servicios y pronunciamientos del DCF sobre
          casos frecuentes, cuantiosos o importantes a jucio del DCF
        </p>

        <a-button
          v-if="!lectureMode"
          @click="addNewMeet"
          class="w-2/5 xl:w-1/4"
          type="primary"
          shape="round"
          icon="plus"
          >REGISTRAR NUEVA REUNIÓN</a-button
        >
        <h2 class="text-left text-xl text-gray-900 font-bold">
          Reuniónes registradas
        </h2>
        <div
          class="w-full flex items-center justify-center gap-4 h-full"
          v-for="(meet, idx) in loadMeetings"
          :key="idx"
        >
          <h1
            class="w-4/5 font-bold9 text-left text-gray-500 bg-gray-50 px-4 h-10 rounded-xl py-auto flex items-center capitalize"
          >
            {{ meet.theme }}
          </h1>
          <a-button
            class="self-end"
            type="primary"
            shape="round"
            icon="eye"
            @click="showMeet(idx)"
          >
            Visualizar</a-button
          >
          <a-button
            v-if="!lectureMode"
            icon="delete"
            class="border-none text-red-500 text-2xl"
            @click="deleteMeet(idx)"
          ></a-button>
        </div>
        <span v-if="loadMeetings.length === 0" class="text-gray-400"
          >No hay reuniones registradas</span
        >
        <div class="w-11/12 grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in annexesLoad"
            :value="option.name_file"
            :key="index"
            :url_download="option.url_file"
          />
        </div>
        <DragDrop
          v-if="!lectureMode"
          label="Anexar archivos de soporte:"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          id="files"
          :fileList="files"
          @files-handler="setFiles"
          maxMB="20"
        />
      </div>
    </Accordion>
    <Modal v-if="showModal" size="3xl">
      <div class="relative mb-5">
        <h1
          class="text-blue-dark text-left text-2xl font-bold"
          v-if="showMeetModal"
        >
          Detalle de la reunión
        </h1>
        <h1 v-else class="text-blue-dark text-left text-2xl font-bold">
          Registrar nueva reunión
        </h1>
        <span
          class="text-2xl absolute bottom-5 right-0 hover:text-gray-500"
          @click="closeModal"
          ><a-icon type="close"></a-icon>
        </span>
      </div>
      <div class="flex justify-between gap-4">
        <BaseDate
          required="true"
          label="Fecha de la reunión"
          v-model="modalForm.date_meeting"
          :disabled="showMeetModal"
        />
        <BaseInput
          required="true"
          label="Temática"
          placeholder="Escribe la temática de la reunión"
          v-model="modalForm.theme"
          :disabled="showMeetModal"
        />
      </div>
      <div class="flex flex-row gap-5 w-4/6">
        <span class="text-sm text-gray-600 font-bold text-left mt-5 mb-0">
          Participantes</span
        >
      </div>
      <div class="flex flex-row gap-5 w-4/6" v-if="!showMeetModal">
        <BaseInput v-model="participant" />
        <a-button
          class="mt-auto"
          size="large"
          type="primary"
          icon="plus"
          shape="circle"
          @click="addParticipant"
          :disabled="showMeetModal || participant === ''"
        ></a-button>
      </div>

      <div
        class="flex flex-row gap-5 w-4/6 items-center"
        v-for="(participant, idx) in modalForm.participants"
        :key="idx"
      >
        <BaseInput v-model="modalForm.participants[idx]" :disabled="true" />
        <a-button
          v-if="!showMeetModal"
          class="border-none text-red-500 text-2xl mt-auto"
          icon="delete"
          @click="removeParticipant(idx)"
        ></a-button>
      </div>
      <BaseTextarea
        class="mb-3"
        required="true"
        label="Descripciones adicionales"
        maxlength="4500"
        v-model="modalForm.description"
        :disabled="showMeetModal"
      ></BaseTextarea>
      <span class="text-red-600 my-5" v-if="message">*{{ message }}</span>
      <div class="flex gap-4 justify-end mt-6" v-if="!showMeetModal">
        <a-button type="danger" ghost shape="round" @click="showModal = false"
          >CANCELAR</a-button
        >
        <a-button type="primary" shape="round" @click="createNewMeet"
          >GUARDAR</a-button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import Products_Reasons from "@/components/Dashboard/FCO/Reports/Products_Reasons.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  components: { Products_Reasons, Accordion },
  props: {
    finishing: { type: Boolean, default: false },
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: false,
      showMeetModal: false,
      loading: false,
      participant: "",
      modalForm: {
        theme: "",
        participants: [],
        description: "",
        date_meeting: "",
      },
      annual_report: this.$route.query.annual_report,
      del_meetings: [],
      new_meetings: [],
      loadMeetings: [],
      reference_file: 22,
      files: [],
      annexesLoad: [],
      message: "",
    };
  },
  created() {
    this.getReport();
  },
  methods: {
    async getReport() {
      this.loading = true;
      let { error, data } = await this.$api.getStepReport(
        this.$route.query.annual_report,
        5
      );
      if (data) {
        this.loadMeetings = data.data.data;
        this.annexesLoad = data.data.annexes;
        this.loading = false;
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    addParticipant() {
      this.modalForm.participants.push(this.participant);
      this.participant = "";
    },
    removeParticipant(idx) {
      this.modalForm.participants.splice(idx, 1);
    },

    async save() {
      if (this.loadMeetings.length > 0)
        this.new_meetings = this.loadMeetings.filter((meet) => !meet?.id);
      let form = {
        annual_report: this.annual_report,
        del_meetings: JSON.stringify(this.del_meetings),
        new_meetings: JSON.stringify(this.new_meetings),
        reference_file: this.reference_file,
      };
      if (this.del_meetings.length > 0 || this.new_meetings.length > 0 || this.files.length > 0) {
        let { error, data } = await this.$api.createNewMeet({
          form:
              this.del_meetings.length > 0 || this.new_meetings.length > 0
                  ? form
                  : this.files.length > 0 ? {
                    annual_report: this.annual_report,
                    reference_file: this.reference_file,
                  } : {},
          files: this.files,
        });
        if (error) {
          this.showToast(
              "error",
              "Ocurrió un error en el guardado de la información. Intente nuevamente."
          );
        } else if (data) {
          this.showToast("success", "Los cambios se guardaron correctamente.");
          this.new_meetings = [];
        }
      }
    },

    checkFormNewMeet() {
      let result = true;
      if (this.modalForm?.date_meeting === "") {
        this.message = "La reunión no tiene fecha.";
        result = false;
      } else if (this.modalForm.theme === "") {
        this.message = "La reunión no tiene tema.";
        result = false;
      } else if (this.modalForm.participants.length == 0) {
        this.message = "La reunión no tiene participantes.";
        result = false;
      } else if (this.modalForm.description === "") {
        this.message = "Ingrese una breve descripción de la reunión.";
        result = false;
      }
      return result;
    },
    createNewMeet() {
      if (this.checkFormNewMeet()) {
        this.loadMeetings.push(this.modalForm);
        this.modalForm = {
          theme: "",
          participants: [],
          description: "",
          date_meeting: "",
        };
        this.showModal = false;
        this.message = "";
      }
    },
    deleteMeet(idx) {
      const deleted = this.loadMeetings.splice(idx, 1);
      if (deleted[0]?.id) this.del_meetings.push(deleted[0].id);
    },
    addNewMeet() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.showMeetModal = false;
      this.modalForm = {};
      this.message = "";
    },
    showMeet(idx) {
      this.showMeetModal = true;
      this.modalForm = this.new_meetings[idx];
      this.showModal = true;
    },
    setFiles(files) {
      this.files = files;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },

  watch: {
    finishing: function (value) {
      if (value) this.save();
    },
    saveSignal: function (value) {
      if (value) this.save();
    },
  },
  beforeDestroy() {
    //TO_DO: Validate if there are changes in all forms
    if (!this.lectureMode) this.save();
  },
};
</script>

<style scoped>
.spinner {
  width: 100%;
  z-index: 9000;
  margin-top: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>
