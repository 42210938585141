var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.section.new_products),function(product,idx){return _c('div',{key:idx,staticClass:"px-5 pb-8 pt-5 flex flex-col gap-4 w-full rounded-xl border mb-5 relative",attrs:{"id":"products"}},[_c('h3',{staticClass:"text-left font-bold"},[_vm._v("Producto")]),(
        _vm.values.selectOptions.products.length > 0 &&
        _vm.values.selectOptions.reasons.length > 0
      )?_c('div',{staticClass:"w-full mx-0"},[_c('div',{staticClass:"grid grid-cols-2 justify-between w-full gap-5"},[(!_vm.lectureMode)?_c('a-button',{staticClass:"border-none text-xl absolute top-0 right-0 mt-3 mr-3",attrs:{"icon":"delete"},on:{"click":function($event){return _vm.deleteProduct(idx)}}}):_vm._e(),_c('BaseSelect',{attrs:{"required":"true","disabled":_vm.lectureMode,"label":"Seleccione el producto","selectOptions":_vm.values.selectOptions.products},model:{value:(_vm.section.new_products[idx].product),callback:function ($$v) {_vm.$set(_vm.section.new_products[idx], "product", $$v)},expression:"section.new_products[idx].product"}}),(_vm.values.selectOptions.reasons.length > 0)?_c('BaseMultiSelect',{staticClass:"relative",attrs:{"title":"Seleccione los motivos","placeholder":"Seleccionar","data":_vm.values.selectOptions.reasons,"valuesSelected":_vm.multiSelectLoad[idx],"label":"name","required":""},on:{"notify-objects":function($event){return _vm.setReasonsSelect($event, idx)}}}):_vm._e()],1),_c('BaseInput',{ref:"total-complaints",refInFor:true,attrs:{"disabled":_vm.lectureMode,"label":_vm.config.numInputLabel,"placeholder":"12389","maxlength":"5","required":"true","numeric":""},model:{value:(_vm.section.new_products[idx].amount),callback:function ($$v) {_vm.$set(_vm.section.new_products[idx], "amount", $$v)},expression:"section.new_products[idx].amount"}})],1):(
        !_vm.lectureMode &&
        _vm.values.selectOptions.products.length == 0 &&
        _vm.values.selectOptions.reasons.length == 0
      )?_c('div',{staticClass:"bg-red-50 rounded-xl p-6 text-red-500"},[_vm._v(" * Es necesario que preseleccione mínimo un producto y motivo "),(!_vm.lectureMode)?_c('a-button',{staticClass:"border-none text-xl absolute top-0 right-0 mt-3 mr-3",attrs:{"icon":"delete"},on:{"click":function($event){return _vm.deleteProduct(idx)}}}):_vm._e()],1):_vm._e()])}),(!_vm.lectureMode)?_c('div',{staticClass:"px-5 py-3 flex gap-4 w-full rounded-xl border items-center cursor-pointer",attrs:{"id":"add-product"}},[_c('a-button',{attrs:{"id":_vm.section.reference_file,"size":"large","type":"primary","shape":"circle","icon":"plus"},on:{"click":_vm.addProduct}}),_c('label',{staticClass:"font-bold",attrs:{"for":_vm.section.reference_file}},[_vm._v("Añadir producto")])],1):_vm._e(),_c('BaseTextarea',{attrs:{"required":"true","disabled":_vm.lectureMode,"label":"Descripción","maxlength":"4500","rows":"4"},model:{value:(_vm.section.description),callback:function ($$v) {_vm.$set(_vm.section, "description", $$v)},expression:"section.description"}}),(_vm.values.annexesLoad.length > 0)?_c('div',[_c('h2',{staticClass:"text-left text-gray-500 text-sm font-bold mt-4"},[_vm._v(" Archivos existentes ")]),_c('div',{staticClass:"w-11/12 grid grid-cols-1 gap-2"},_vm._l((_vm.values.annexesLoad),function(option,index){return _c('BaseDownload',{key:index,attrs:{"value":option.name_file,"url_download":option.url_file}})}),1)]):_vm._e(),(!_vm.lectureMode)?_c('DragDrop',{attrs:{"id":`files${_vm.config.section}`,"label":"Anexar archivos de soporte:","buttonLabel":"SELECCIONE SUS ARCHIVOS","fileList":_vm.section.files,"maxMB":"20"},on:{"files-handler":_vm.setSectionFiles}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }