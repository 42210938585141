<template>
  <section>
    <div
      id="product"
      class="px-5 pb-8 pt-5 flex flex-col gap-4 w-full rounded-xl border"
    >
      <h3 class="text-left font-bold">Productos</h3>
      <div class="w-2/3 mx-0">
        <BaseMultiSelect
          v-if="optionsProducts && optionsProducts.length > 0"
          ref="products"
          id="products"
          title="Seleccione los productos"
          :valuesSelected="multiSelectLoad.productLoad"
          placeholder="Seleccionar"
          :data="optionsProducts"
          label="name"
          @notify-objects="setProductsSelect($event)"
          class="relative"
          :required="true"
        />
      </div>
      <BaseTextarea
        required="true"
        label="Descripción"
        maxlength="4500"
        v-model="product.description"
        :disabled="lectureMode"
      ></BaseTextarea>

      <div v-if="annexes.productAnnexesLoad.length > 0">
        <h2 class="text-left text-gray-500 text-sm font-bold">
          Archivos existentes
        </h2>
        <div class="w-11/12 grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in annexes.productAnnexesLoad"
            :value="option.name_file"
            :key="index"
            :url_download="option.url_file"
          />
        </div>
      </div>
      <DragDrop
        v-if="!lectureMode"
        label="Anexar archivos de soporte:"
        buttonLabel="SELECCIONE SUS ARCHIVOS"
        id="productFiles"
        :fileList="productFiles"
        @files-handler="setFiles($event, true)"
        maxMB="20"
      />
    </div>
    <div
      id="reasons"
      class="px-5 pb-8 pt-5 flex flex-col gap-4 w-full rounded-xl border mt-5"
    >
      <h3 class="text-left font-bold">Motivos</h3>
      <div class="w-2/3 mx-0">
        <BaseMultiSelect
          required
          v-if="optionsReasons && optionsReasons.length > 0"
          title="Seleccione los motivos"
          placeholder="Seleccionar"
          :valuesSelected="multiSelectLoad.reasonsLoad"
          :data="optionsReasons"
          label="name"
          @notify-objects="setReasonsSelect($event)"
          class="relative"
        />
      </div>
      <BaseTextarea
        required="true"
        :disabled="lectureMode"
        label="Descripción"
        maxlength="4500"
        v-model="reason.description"
      ></BaseTextarea>
      <div v-if="annexes.reasonsAnnexesLoad.length > 0">
        <h2 class="text-left text-gray-500 text-sm font-bold">
          Archivos existentes
        </h2>
        <div class="w-11/12 grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in annexes.reasonsAnnexesLoad"
            :value="option.name_file"
            :key="index"
            :url_download="option.url_file"
          />
        </div>
      </div>
      <DragDrop
        v-if="!lectureMode"
        label="Anexar archivos de soporte:"
        buttonLabel="SELECCIONE SUS ARCHIVOS"
        id="reasonsFiles"
        :fileList="reasonsFiles"
        @files-handler="setFiles($event, false)"
        maxMB="20"
      />
    </div>
  </section>
</template>
<script>
export default {
  props: {
    lectureMode: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    config: { type: Object, required: true },
    data: { type: Object, required: false },
    loading: { type: Boolean, default: false },
  },
  created() {
    this.company_id = this.$route.query.company_id;

    this.product.reference_file = this.config.refProductsFiles;

    this.reason.reference_file = this.config.refReasonsFiles;
    if (this.data) {
      this.loadProducts();
      this.loadReasons();
      this.jsonToCompare = this.generateJsonToCompare();
    }
    this.getProducts();
    this.getReasons();
  },

  data() {
    return {
      company_id: "",
      product: {
        type_product: "product",
        list_data: "",
        description: "",
        reference_file: "",
      },
      reason: {
        type_product: "reason",
        list_data: "",
        description: "",
        reference_file: "",
      },
      optionsProducts: [],
      optionsReasons: [],

      productFiles: [],
      reasonsFiles: [],
      multiSelectLoad: {
        productLoad: [],
        reasonsLoad: [],
      },
      annexes: {
        reasonsAnnexesLoad: [],
        productAnnexesLoad: [],
      },
      productsSelected: [],
      reasonsSelected: [],
      jsonToCompare: "",
    };
  },
  methods: {
    loadProducts() {
      const product = this.data.products_reasons;
      this.product.description =
        product.product_description !== "null" &&
        product.product_description !== "undefined"
          ? product.product_description
          : "";
      this.multiSelectLoad.productLoad = product.products;
      this.annexes.productAnnexesLoad = this.data.annexes.filter(
        (annexe) => annexe.reference == 1
      );
      this.product.list_data = this.multiSelectLoad.productLoad;
      this.product.list_data.forEach((productId) => {
        this.productsSelected = this.productsSelected.concat(
          this.optionsProducts.filter((product) => product.id == productId)
        );
      });
    },
    loadReasons() {
      const reasons = this.data.products_reasons;
      this.reason.description =
        reasons.reason_description !== "null" &&
        reasons.reason_description !== "undefined"
          ? reasons.reason_description
          : "";
      this.multiSelectLoad.reasonsLoad = reasons.reasons;
      this.annexes.reasonsAnnexesLoad = this.data.annexes.filter(
        (annexe) => annexe.reference == 2
      );
    },
    setFiles(event, type_product) {
      if (type_product) this.productFiles = event;
      else this.reasonsFiles = event;
    },
    setProductsSelect(products) {
      this.productsSelected = [];
      this.product.list_data = products.map((item) => item.id);
      this.product.list_data.forEach((productId) => {
        this.productsSelected = this.productsSelected.concat(
          this.optionsProducts.filter((product) => product.id == productId)
        );
      });
      this.$emit("products", this.productsSelected);
    },
    setReasonsSelect(reasons) {
      this.reasonsSelected = [];
      this.reason.list_data = reasons.map((item) => item.id);
      this.reason.list_data.forEach((productId) => {
        this.reasonsSelected = this.reasonsSelected.concat(
          this.optionsReasons.filter((product) => product.id == productId)
        );
      });
      this.$emit("reasons", this.reasonsSelected);
    },
    async save() {
      const productResult = await this.saveDetail(
        this.product,
        this.productFiles,
          "Productos"
      );
      const reasonResult = await this.saveDetail(
        this.reason,
        this.reasonsFiles,
          "Motivos"
      );
      if (productResult === false || reasonResult === false) {
        this.showToast("info", "Algunos datos no se guardaron correctamente.");
      } else {
        this.showToast("success", "Guardando como borrador...");
      }
    },
    async saveDetail(form, files, type) {
      if (this.validateForm(form)) {
        const msg = `Por favor completa todos los datos en la sección de ${type}`;
        this.$emit('on-error', msg)
        return false
      } else {
        form.annual_report = this.$route.query.annual_report;
        form.report_type = this.config.report_type;
        let { error, data } = await this.$api.createReportDetail({ form, files });
        if (data) return true;
        if (error) return false;
      }
    },
    validateForm(form) {
      const validate = ["", null, undefined]
      if ((Array.isArray(form.list_data) && form.list_data.length > 0) && validate.includes(form.description)) {
        return true;
      } else if ((Array.isArray(form.list_data) && form.list_data.length === 0) && !validate.includes(form.description)) {
        return true;
      } else {
        return false;
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    generateJsonToCompare() {
      let array = [];
      array.push(this.product);
      array.push(this.reason);
      return JSON.stringify(array);
    },
    async getProducts() {
      this.optionsProducts = await this.$store.dispatch(
        "form/loadProductsOptions",
        {
          company: this.company_id,
        }
      );
    },
    async getReasons() {
      this.optionsReasons = await this.$store.dispatch(
        "form/loadReasonsOptions",
        {
          company: this.company_id,
        }
      );
    },
  },
  beforeDestroy() {
    //TO_DO: Validate if there are changes in all forms

    if (!this.lectureMode) {
      if (this.jsonToCompare !== this.generateJsonToCompare()) this.save();
    }
  },
  watch: {
    saving: function (value) {
      if (value && !this.lectureMode)
        if (this.jsonToCompare !== this.generateJsonToCompare()) this.save();
    },
  },
};
</script>
