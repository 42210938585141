<template>
  <div class="h-min-content xl:w-full sm:w-full px-0">
    <div v-if="loading" class="spinner mx-auto">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <Accordion header="De la función de atención al consumidor financiero">
      <Products_Reasons
        v-if="!loading"
        :config="{
          annual_report,
          report_type,
          refProductsFiles: 10,
          refReasonsFiles: 11,
        }"
        :data="reasons_products_data"
        @products="productsSelected = $event"
        @reasons="reasonsSelected = $event"
        :saving="toSave"
        :loading="loading"
        :lectureMode="lectureMode"
      />

      <h2 class="my-6 text-left text-xl text-gray-900 font-bold w-full">
        Número total de peticiones y solicitudes formuladas por los
        consumidores, diferentes a vocerías, quejas y conciliaciones, indicando:
      </h2>
      <div id="form-block flex gap-4">
        <div class="w-1/3">
          <BaseInput
            required="true"
            numeric
            maxlength="5"
            :disabled="lectureMode"
            label="Atendidas directamente en la DCF"
            v-model="sectionForm.attended_directly_dcf"
          />
        </div>
        <BaseTextarea
          required="true"
          :disabled="lectureMode"
          label="Descripción"
          maxlength="4500"
          rows="3"
          v-model="sectionForm.attended_description"
        ></BaseTextarea>
        <div class="w-1/3">
          <BaseInput
            required="true"
            numeric
            maxlength="5"
            :disabled="lectureMode"
            label="Rechazadas directamente en la DCF"
            v-model="sectionForm.rejected_directly_dcf"
          />
        </div>
        <BaseTextarea
          required="true"
          :disabled="lectureMode"
          label="Descripción "
          maxlength="4500"
          rows="3"
          v-model="sectionForm.rejected_description"
        ></BaseTextarea>
        <div class="w-1/3">
          <BaseInput
            required="true"
            numeric
            maxlength="5"
            :disabled="lectureMode"
            label="Trasladadas a la entidad"
            v-model="sectionForm.transferred_company"
          />
        </div>
        <BaseTextarea
          required="true"
          :disabled="lectureMode"
          label="Descripción "
          maxlength="4500"
          rows="3"
          v-model="sectionForm.transferred_description"
        ></BaseTextarea>
        <div v-if="annexes.sectionAnnexesLoad.length > 0">
          <h2 class="text-left text-gray-500 text-sm font-bold">
            Archivos existentes
          </h2>
          <div class="w-11/12 grid grid-cols-1 gap-2">
            <BaseDownload
              v-for="(option, index) in annexes.sectionAnnexesLoad"
              :value="option.name_file"
              :key="index"
              :url_download="option.url_file"
            />
          </div>
        </div>
        <DragDrop
          v-if="!lectureMode"
          label="Anexar archivos de soporte:"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          id="complaintFiles"
          :fileList="sectionFiles"
          @files-handler="setSectionFiles"
          maxMB="20"
        />
      </div>
    </Accordion>
  </div>
</template>

<script>
import Products_Reasons from "@/components/Dashboard/FCO/Reports/Products_Reasons.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  components: { Products_Reasons, Accordion },
  props: {
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  data() {
    return {
      company_id: this.$route.query.company_id,
      annual_report: this.$route.query.annual_report,
      report_type: 3, // Step 3
      reasons_products_data: null,
      sectionForm: {
        reference_file: 12,
        attended_directly_dcf: "",
        attended_description: "",
        rejected_directly_dcf: "",
        rejected_description: "",
        transferred_company: "",
        transferred_description: "",
      },

      annexes: {
        sectionAnnexesLoad: [],
      },

      sectionFiles: [],
      jsonToCompare: "",
      loading: false,
      accordionOpen: false,
      toSave: false,
    };
  },
  created() {
    this.getProducts();
    this.getReasons();
    this.getReport();
  },
  methods: {
    async getReport() {
      this.loading = true;
      let { error, data } = await this.$api.getStepReport(
        this.$route.query.annual_report,
        3
      );
      if (data) {
        this.reasons_products_data = data.data;
        this.loading = false;
        this.loadSections(data.data);
        this.jsonToCompare = this.generateJsonToCompare();
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },

    loadSections(data) {
      this.sectionForm.attended_description =
        data.attention.attended_description;
      this.sectionForm.attended_directly_dcf =
        data.attention.attended_directly_dcf;
      this.sectionForm.rejected_description =
        data.attention.rejected_description;
      this.sectionForm.rejected_directly_dcf =
        data.attention.rejected_directly_dcf;
      this.sectionForm.transferred_company = data.attention.transferred_company;
      this.sectionForm.transferred_description =
        data.attention.transferred_description;

      this.annexes.sectionAnnexesLoad = data.annexes.filter(
        (annexe) => (annexe.reference = 12)
      );
    },
    async save() {
      const sectionResult = await this.saveSection();
      if (sectionResult === false) {
        this.showToast("info", "Algunos datos no se guardaron correctamente.");
      } else {
        this.showToast("success", "Guardando como borrador...");
      }
    },
    async saveSection() {
      this.sectionForm.annual_report = this.annual_report;
      const fields = {
        attended_directly_dcf: "Atendidas directamente en la DCF",
        attended_description: "Descripción atendidas directamente en la DCF",
        rejected_directly_dcf: "Rechazadas directamente en la DCF",
        rejected_description: "Descripción rechazadas directamente en la DCF",
        transferred_company: "Trasladadas a la entidad",
        transferred_description: "Descripción trasladadas a la entidad",
      }
      const emptyFields = []
      Object.keys(this.sectionForm).forEach(key => {
        if (["", null, undefined].includes(this.sectionForm[key])) {
          emptyFields.push(fields[key])
        }
      })
      if (emptyFields.length > 0) {
        const msg = "En el Paso 3 algunos campos quedaron incompletos. " + emptyFields.join(", ")
        this.showToast("info", msg)
        this.$emit('on-step-error', msg, 2);
      }
      const forms = { form: this.sectionForm, files: this.sectionFiles };
      let { error, data } = await this.$api.createAttention(forms);
      if (data) return true;
      if (error) return false;
    },
    generateJsonToCompare() {
      return JSON.stringify(this.sectionForm);
    },
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    },
    toggleSubAccordion() {
      this.subAccordionOpen = !this.subAccordionOpen;
    },

    setSectionFiles(event) {
      this.sectionFiles = event;
    },

    async getProducts() {
      this.optionsProducts = await this.$store.dispatch(
        "form/loadProductsOptions",
        {
          company: this.company_id,
        }
      );
    },
    async getReasons() {
      this.optionsReasons = await this.$store.dispatch(
        "form/loadReasonsOptions",
        {
          company: this.company_id,
        }
      );
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  beforeDestroy() {
    //TO_DO: Validate if there are changes in all forms

    if (!this.lectureMode) {
      if (this.jsonToCompare !== this.generateJsonToCompare()) this.save();
    }
  },
  computed: {
    buildJsonForm: function () {},
    getProductsSelected: function () {
      return;
    },
  },
  watch: {
    saveSignal: function (value) {
      if (value) {
        if (this.jsonToCompare !== this.generateJsonToCompare()) {
          this.save();
        }
        this.toSave = true;
        setTimeout(() => {
          this.toSave = false;
        }, 1500);
      }
    },
  },
};
</script>

<style scoped>
.spinner {
  width: 100%;
  z-index: 9000;
  margin-top: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>
